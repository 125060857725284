const eosErrorArray = {
    '1': {
        'en_US': '没有DID认证',
        'zh_CN': '没有DID认证',
    },
    '2': {
        'en_US': '代币简称已和其他代币重复，请重新取名',
        'zh_CN': '代币简称已和其他代币重复，请重新取名',
    },
    '3': {
        'en_US': '创建代币不可用',
        'zh_CN': '创建代币不可用',
    },
    '4': {
        'en_US': '该代币符号不可用',
        'zh_CN': '该代币符号不可用',
    },
    '5': {
        'en_US': '代币不存在',
        'zh_CN': '代币不存在',
    },
    '6': {
        'en_US': 'AMAX不足',
        'zh_CN': 'AMAX不足',
    },
    '7': {
        'en_US': '代币符号不匹配',
        'zh_CN': '代币符号不匹配',
    },
    '8': {
        'en_US': '代币符号太短',
        'zh_CN': '代币符号太短',
    },
    '3010000': {
        'en_US': 'chain type exception',
        'zh_CN': '链类型异常',
    },
    '3010001': {
        'en_US': 'Invalid name',
        'zh_CN': '无效的名称',
    },
    '3010002': {
        'en_US': 'Invalid public key',
        'zh_CN': '无效的公钥',
    },
    '3010003': {
        'en_US': 'Invalid private key',
        'zh_CN': '无效的私钥',
    },
    '3010004': {
        'en_US': 'Invalid authority',
        'zh_CN': '无效的授权',
    },
    '3010005': {
        'en_US': 'Invalid action',
        'zh_CN': '无效的动作',
    },
    '3010006': {
        'en_US': 'Invalid transaction',
        'zh_CN': '无效的交易',
    },
    '3010007': {
        'en_US': 'Invalid ABI',
        'zh_CN': '无效的ABI',
    },
    '3010008': {
        'en_US': 'Invalid block ID',
        'zh_CN': '无效的区块ID',
    },
    '3010009': {
        'en_US': 'Invalid transaction ID',
        'zh_CN': '无效的交易ID',
    },
    '3010010': {
        'en_US': 'Invalid packed transaction',
        'zh_CN': '无效的打包交易',
    },
    '3010011': {
        'en_US': 'Invalid asset',
        'zh_CN': '无效的资产',
    },
    '3010012': {
        'en_US': 'Invalid chain ID',
        'zh_CN': '无效的链ID',
    },
    '3010013': {
        'en_US': 'Invalid fixed key',
        'zh_CN': '无效的固定密钥',
    },
    '3010014': {
        'en_US': 'Invalid symbol',
        'zh_CN': '无效的代币符号',
    },
    '3015000': {
        'en_US': 'ABI exception',
        'zh_CN': 'ABI异常',
    },
    '3015001': {
        'en_US': 'No ABI found',
        'zh_CN': '没有找到ABI',
    },
    '3015002': {
        'en_US': 'Invalid Ricardian Clause',
        'zh_CN': '无效的李嘉图语句',
    },
    '3015003': {
        'en_US': 'Invalid Ricardian Action',
        'zh_CN': '无效的李嘉图动作',
    },
    '3015004': {
        'en_US': 'The type defined in the ABI is invalid',
        'zh_CN': 'ABI中定义的类型无效',
    },
    '3015005': {
        'en_US': 'Duplicate type definition in the ABI',
        'zh_CN': 'ABI中存在重复定义的类型',
    },
    '3015006': {
        'en_US': 'Duplicate struct definition in the ABI',
        'zh_CN': 'ABI中存在重复定义的结构',
    },
    '3015007': {
        'en_US': 'Duplicate action definition in the ABI',
        'zh_CN': 'ABI中存在重复定义的动作',
    },
    '3015008': {
        'en_US': 'Duplicate table definition in the ABI',
        'zh_CN': 'ABI中存在重复定义的数据表',
    },
    '3015009': {
        'en_US': 'Duplicate error message definition in the ABI',
        'zh_CN': 'ABI中存在重复定义的错误信息',
    },
    '3015010': {
        'en_US': 'ABI serialization time has exceeded the deadline',
        'zh_CN': 'ABI序列化时间超过截止值',
    },
    '3015011': {
        'en_US': 'ABI recursive definition has exceeded the max recursion depth',
        'zh_CN': 'ABI中的递归定义超过最大允许深度',
    },
    '3015012': {
        'en_US': 'Circular definition is detected in the ABI',
        'zh_CN': 'ABI中检测到循环定义',
    },
    '3015013': {
        'en_US': 'Unpack data exception',
        'zh_CN': '解包数据发生异常',
    },
    '3015014': {
        'en_US': 'Pack data exception',
        'zh_CN': '打包数据发生异常',
    },
    '3015015': {
        'en_US': 'Duplicate variant definition in the ABI',
        'zh_CN': 'ABI中存在重复定义的变量',
    },
    '3015016': {
        'en_US': 'ABI has an unsupported version',
        'zh_CN': '不支持的ABI版本',
    },
    '3020000': {
        'en_US': 'Fork database exception',
        'zh_CN': '分叉数据库异常',
    },
    '3020001': {
        'en_US': 'Block can not be found',
        'zh_CN': '找不到区块',
    },
    '3030000': {
        'en_US': 'Block exception',
        'zh_CN': '区块异常',
    },
    '3030001': {
        'en_US': 'Unlinkable block',
        'zh_CN': '无法链接的区块',
    },
    '3030002': {
        'en_US': 'Transaction outputs in block do not match transaction outputs from applying block',
        'zh_CN': '指定区块与待应用区块中的交易输出不匹配',
    },
    '3030003': {
        'en_US': 'Block does not guarantee concurrent execution without conflicts',
        'zh_CN': '区块不能保证无冲突并发执行',
    },
    '3030004': {
        'en_US': 'Shard locks in block are incorrect or mal-formed',
        'zh_CN': '区块的分片锁不正确或格式错误',
    },
    '3030005': {
        'en_US': 'Block exhausted allowed resources',
        'zh_CN': '区块已耗尽许可的资源',
    },
    '3030006': {
        'en_US': 'Block is too old to push',
        'zh_CN': '区块太陈旧，无法提交',
    },
    '3030007': {
        'en_US': 'Block is from the future',
        'zh_CN': '区块时间过早',
    },
    '3030008': {
        'en_US': 'Block is not signed with expected key',
        'zh_CN': '区块签名与密钥不一致',
    },
    '3030009': {
        'en_US': 'Block is not signed by expected producer',
        'zh_CN': '区块签名与出块人不一致',
    },
    '3040000': {
        'en_US': 'Transaction exception',
        'zh_CN': '交易异常',
    },
    '3040001': {
        'en_US': 'Error decompressing transaction',
        'zh_CN': '解压交易失败',
    },
    '3040002': {
        'en_US': 'Transaction should have at least one normal action',
        'zh_CN': '交易至少应当包含一个常规动作',
    },
    '3040003': {
        'en_US': 'Transaction should have at least one required authority',
        'zh_CN': '交易至少应当包含一个授权',
    },
    '3040004': {
        'en_US': 'Context-free action should have no required authority',
        'zh_CN': '上下文无关动作不应当包含授权',
    },
    '3040005': {
        'en_US': 'Expired Transaction',
        'zh_CN': '超时的交易',
    },
    '3040006': {
        'en_US': 'Transaction Expiration Too Far',
        'zh_CN': '交易超时过久',
    },
    '3040007': {
        'en_US': 'Invalid Reference Block',
        'zh_CN': '无效的参考块',
    },
    '3040008': {
        'en_US': 'Duplicate transaction',
        'zh_CN': '重复的交易',
    },
    '3040009': {
        'en_US': 'Duplicate deferred transaction',
        'zh_CN': '重复的延迟交易',
    },
    '3040010': {
        'en_US': 'Context free action is not allowed inside generated transaction',
        'zh_CN': '在生成的交易中不允许出现上下文无关动作',
    },
    '3040011': {
        'en_US': 'The transaction can not be found',
        'zh_CN': '交易找不到',
    },
    '3040012': {
        'en_US': 'Pushing too many transactions at once',
        'zh_CN': '同时提交过多的交易',
    },
    '3040013': {
        'en_US': 'Transaction is too big',
        'zh_CN': '交易过大',
    },
    '3040014': {
        'en_US': 'Unknown transaction compression',
        'zh_CN': '未知的交易压缩方式',
    },
    '3050000': {
        'en_US': 'Action validate exception',
        'zh_CN': '动作验证异常',
    },
    '3050001': {
        'en_US': 'Account name already exists',
        'zh_CN': '账号名已经存在',
    },
    '3050002': {
        'en_US': 'Invalid Action Arguments',
        'zh_CN': '无效的动作参数',
    },
    '3050003': {
        'en_US': 'eosio_assert_message assertion failure',
        'zh_CN': '消息条件验证失败',
    },
    '3050004': {
        'en_US': 'eosio_assert_code assertion failure',
        'zh_CN': '代码条件验证失败',
    },
    '3050005': {
        'en_US': 'Action can not be found',
        'zh_CN': '找不到动作',
    },
    '3050006': {
        'en_US': 'Mismatch between action data and its struct',
        'zh_CN': '动作数据和结构定义不匹配',
    },
    '3050007': {
        'en_US': 'Attempt to use unaccessible API',
        'zh_CN': '试图访问不许可的API',
    },
    '3050008': {
        'en_US': 'Abort Called',
        'zh_CN': '中止被调用',
    },
    '3050009': {
        'en_US': 'Inline Action exceeds maximum size limit',
        'zh_CN': '内联动作超过允许的最大尺寸',
    },
    '3060000': {
        'en_US': 'Database exception',
        'zh_CN': '数据库异常',
    },
    '3060001': {
        'en_US': 'Permission Query Exception',
        'zh_CN': '许可查询异常',
    },
    '3060002': {
        'en_US': 'Account Query Exception',
        'zh_CN': '账号查询异常',
    },
    '3060003': {
        'en_US': 'Contract Table Query Exception',
        'zh_CN': '合约数据表查询异常',
    },
    '3060004': {
        'en_US': 'Contract Query Exception',
        'zh_CN': '合约查询异常',
    },
    '3060100': {
        'en_US': 'Guard Exception',
        'zh_CN': '保护性异常',
    },
    '3060101': {
        'en_US': 'Database usage is at unsafe levels',
        'zh_CN': '数据库利用处于不安全等级',
    },
    '3060102': {
        'en_US': 'Reversible block log usage is at unsafe levels',
        'zh_CN': '可逆块日志利用处于不安全等级',
    },
    '3070000': {
        'en_US': 'WASM Exception',
        'zh_CN': 'WASM异常',
    },
    '3070001': {
        'en_US': 'Error in WASM page memory',
        'zh_CN': 'WASM内存页错误',
    },
    '3070002': {
        'en_US': 'Runtime Error Processing WASM',
        'zh_CN': '处理WASM时发生运行时错误',
    },
    '3070003': {
        'en_US': 'Serialization Error Processing WASM',
        'zh_CN': '处理WASM时发生序列化错误',
    },
    '3070004': {
        'en_US': 'memcpy with overlapping memory',
        'zh_CN': '内存拷贝时发生地址重叠',
    },
    '3070005': {
        'en_US': 'binaryen exception',
        'zh_CN': 'binaryen异常',
    },
    '3080000': {
        'en_US': 'Resource exhausted exception',
        'zh_CN': '资源耗尽异常',
    },
    '3080001': {
        'en_US': 'Account using more than allotted RAM usage',
        'zh_CN': '账号使用的内存超限',
    },
    '3080002': {
        'en_US': 'Transaction exceeded the current network usage limit imposed on the transaction',
        'zh_CN': '交易网络占用超限',
    },
    '3080003': {
        'en_US': 'Transaction network usage is too much for the remaining allowable usage of the current block',
        'zh_CN': '交易网络占用过高',
    },
    '3080004': {
        'en_US': 'Transaction exceeded the current CPU usage limit imposed on the transaction',
        'zh_CN': '交易CPU占用超限',
    },
    '3080005': {
        'en_US': 'Transaction CPU usage is too much for the remaining allowable usage of the current block',
        'zh_CN': '交易CPU占用过高',
    },
    '3080006': {
        'en_US': 'Transaction took too long',
        'zh_CN': '交易用时过长',
    },
    '3080007': {
        'en_US': 'Transaction exceeded the current greylisted account network usage limit',
        'zh_CN': '交易超过当前灰名单账号的网络用量上限',
    },
    '3080008': {
        'en_US': 'Transaction exceeded the current greylisted account CPU usage limit',
        'zh_CN': '交易超过当前灰名单账号的CPU用量上限',
    },
    '3081001': {
        'en_US': 'Transaction reached the deadline set due to leeway on account CPU limits',
        'zh_CN': '由于账号CPU限制，交易已经达到截止区',
    },
    '3090000': {
        'en_US': 'Authorization exception',
        'zh_CN': '授权异常',
    },
    '3090001': {
        'en_US': 'Duplicate signature included',
        'zh_CN': '包含重复的签名',
    },
    '3090002': {
        'en_US': 'Irrelevant signature included',
        'zh_CN': '包含不相关的签名',
    },
    '3090003': {
        'en_US': 'Provided keys, permissions, and delays do not satisfy declared authorizations',
        'zh_CN': '提供的密钥、许可和延时不能满足声称的授权',
    },
    '3090004': {
        'en_US': 'Missing required authority',
        'zh_CN': '授权丢失',
    },
    '3090005': {
        'en_US': 'Irrelevant authority included',
        'zh_CN': '包含不相关的授权',
    },
    '3090006': {
        'en_US': 'Insufficient delay',
        'zh_CN': '延时不足',
    },
    '3090007': {
        'en_US': 'Invalid Permission',
        'zh_CN': '许可无效',
    },
    '3090008': {
        'en_US': 'The action is not allowed to be linked with minimum permission',
        'zh_CN': '不允许该动作链接到最小许可',
    },
    '3090009': {
        'en_US': 'The parent permission is invalid',
        'zh_CN': '父级许可无效',
    },
    '3100000': {
        'en_US': 'Miscellaneous exception',
        'zh_CN': '其他异常',
    },
    '3100001': {
        'en_US': 'Internal state is no longer consistent',
        'zh_CN': '内部状态不一致',
    },
    '3100002': {
        'en_US': 'Unknown block',
        'zh_CN': '未知区块',
    },
    '3100003': {
        'en_US': 'Unknown transaction',
        'zh_CN': '未知交易',
    },
    '3100004': {
        'en_US': 'Corrupted reversible block database was fixed',
        'zh_CN': '被破坏的可逆区块数据库已修复',
    },
    '3100005': {
        'en_US': 'Extracted genesis state from blocks.log',
        'zh_CN': '从区块日志中提取的创世状态',
    },
    '3100006': {
        'en_US': 'Subjective exception thrown during block production',
        'zh_CN': '出块时抛出异常',
    },
    '3100007': {
        'en_US': 'Multiple voter info detected',
        'zh_CN': '检测到多个投票人信息',
    },
    '3100008': {
        'en_US': 'Feature is currently unsupported',
        'zh_CN': '当前不支持的特性',
    },
    '3100009': {
        'en_US': 'Node management operation successfully executed',
        'zh_CN': '结果管理操作执行成功',
    },
    '3110000': {
        'en_US': 'Plugin exception',
        'zh_CN': '插件异常',
    },
    '3110001': {
        'en_US': 'Missing Chain API Plugin',
        'zh_CN': 'Chain API插件丢失',
    },
    '3110002': {
        'en_US': 'Missing Wallet API Plugin',
        'zh_CN': 'Wallet API插件丢失',
    },
    '3110003': {
        'en_US': 'Missing History API Plugin',
        'zh_CN': 'History API插件丢失',
    },
    '3110004': {
        'en_US': 'Missing Net API Plugin',
        'zh_CN': 'Net API插件丢失',
    },
    '3110005': {
        'en_US': 'Missing Chain Plugin',
        'zh_CN': 'Chain插件丢失',
    },
    '3110006': {
        'en_US': 'Incorrect plugin configuration',
        'zh_CN': '插件配置不正确',
    },
    '3120000': {
        'en_US': 'Wallet exception',
        'zh_CN': '钱包异常',
    },
    '3120001': {
        'en_US': 'Wallet already exists',
        'zh_CN': '钱包已经存在',
    },
    '3120002': {
        'en_US': 'Nonexistent wallet',
        'zh_CN': '钱包不存在',
    },
    '3120003': {
        'en_US': 'Locked wallet',
        'zh_CN': '已锁定的钱包',
    },
    '3120004': {
        'en_US': 'Missing public key',
        'zh_CN': '公钥丢失',
    },
    '3120005': {
        'en_US': 'Invalid wallet password',
        'zh_CN': '无效的钱包密码',
    },
    '3120006': {
        'en_US': 'No available wallet',
        'zh_CN': '没有有效的钱包',
    },
    '3120007': {
        'en_US': 'Already unlocked',
        'zh_CN': '已经解锁',
    },
    '3120008': {
        'en_US': 'Key already exists',
        'zh_CN': '密钥已经存在',
    },
    '3120009': {
        'en_US': 'Nonexistent key',
        'zh_CN': '不存在的密钥',
    },
    '3120010': {
        'en_US': 'Unsupported key type',
        'zh_CN': '不支持的密钥类型',
    },
    '3120011': {
        'en_US': 'Wallet lock timeout is invalid',
        'zh_CN': '钱包锁定超时无效',
    },
    '3120012': {
        'en_US': 'Secure Enclave Exception',
        'zh_CN': '安全专区异常',
    },
    '3130000': {
        'en_US': 'Actor or contract whitelist/blacklist exception',
        'zh_CN': '执行人或合约白名单/黑名单异常',
    },
    '3130001': {
        'en_US': 'Authorizing actor of transaction is not on the whitelist',
        'zh_CN': '交易的授权执行人不在白名单中',
    },
    '3130002': {
        'en_US': 'Authorizing actor of transaction is on the blacklist',
        'zh_CN': '交易的授权执行人在黑名单中',
    },
    '3130003': {
        'en_US': 'Contract to execute is not on the whitelist',
        'zh_CN': '要执行的合约不在白名单中',
    },
    '3130004': {
        'en_US': 'Contract to execute is on the blacklist',
        'zh_CN': '要执行的合约在黑名单中',
    },
    '3130005': {
        'en_US': 'Action to execute is on the blacklist',
        'zh_CN': '要执行的动作在黑名单中',
    },
    '3130006': {
        'en_US': 'Public key in authority is on the blacklist',
        'zh_CN': '授权中的公钥在黑名单中',
    },
    '3140000': {
        'en_US': 'Exceptions that are allowed to bubble out of emit calls in controller',
        'zh_CN': '控制器异常',
    },
    '3140001': {
        'en_US': 'Block does not match checkpoint',
        'zh_CN': '区块与检查点不匹配',
    },
    '3160000': {
        'en_US': 'Contract exception',
        'zh_CN': '合约异常',
    },
    '3160001': {
        'en_US': 'The payer of the table data is invalid',
        'zh_CN': '表数据支付账号无效',
    },
    '3160002': {
        'en_US': 'Table access violation',
        'zh_CN': '数据表访问违规',
    },
    '3160003': {
        'en_US': 'Invalid table iterator',
        'zh_CN': '无效的数据表迭代器',
    },
    '3160004': {
        'en_US': 'Table can not be found inside the cache',
        'zh_CN': '缓存中找不到指定的数据表',
    },
    '3160005': {
        'en_US': 'The table operation is not allowed',
        'zh_CN': '不允许数据表操作',
    },
    '3160006': {
        'en_US': 'Invalid contract vm type',
        'zh_CN': '无效的合约虚拟机类型',
    },
    '3160007': {
        'en_US': 'Invalid contract vm version',
        'zh_CN': '无效的合约虚拟机版本',
    },
    '3160008': {
        'en_US': 'Contract is already running this version of code',
        'zh_CN': '合约已经在运行这个版本的代码',
    },
    '3160009': {
        'en_US': 'No wast file found',
        'zh_CN': '，没有找到wast文件',
    },
    '3160010': {
        'en_US': 'No abi file found',
        'zh_CN': '没有找到abi文件',
    },
    '3170000': {
        'en_US': 'Producer exception',
        'zh_CN': '出块人异常',
    },
    '3170001': {
        'en_US': 'Producer private key is not available',
        'zh_CN': '出块人私钥无效',
    },
    '3170002': {
        'en_US': 'Pending block state is missing',
        'zh_CN': '待定区块状态丢失',
    },
    '3170003': {
        'en_US': 'Producer is double confirming known range',
        'zh_CN': '出块人双重确认',
    },
    '3170004': {
        'en_US': 'Producer schedule exception',
        'zh_CN': '出块人调度计划异常',
    },
    '3170006': {
        'en_US': 'The producer is not part of current schedule',
        'zh_CN': '出块人不属于当前安排计划',
    },
    '3170007': {
        'en_US': 'The configured snapshot directory does not exist',
        'zh_CN': '配置的快照目录不存在',
    },
    '3170008': {
        'en_US': 'The requested snapshot already exists',
        'zh_CN': '请求的快照已经存在',
    },
    '3180000': {
        'en_US': 'Reversible Blocks exception',
        'zh_CN': '可逆区块异常',
    },
    '3180001': {
        'en_US': 'Invalid reversible blocks directory',
        'zh_CN': '无效的可逆区块目录',
    },
    '3180002': {
        'en_US': 'Backup directory for reversible blocks already existg',
        'zh_CN': '可逆块的备份目录已存在',
    },
    '3180003': {
        'en_US': 'Gap in the reversible blocks database',
        'zh_CN': '不连续的可逆区块数据库',
    },
    '3190000': {
        'en_US': 'Block log exception',
        'zh_CN': '区块日志异常',
    },
    '3190001': {
        'en_US': 'unsupported version of block log',
        'zh_CN': '不支持的区块日志版本',
    },
    '3190002': {
        'en_US': 'fail to append block to the block log',
        'zh_CN': '向区块日志添加区块时失败',
    },
    '3190003': {
        'en_US': 'block log can not be found',
        'zh_CN': '找不到区块日志',
    },
    '3190004': {
        'en_US': 'block log backup dir already exists',
        'zh_CN': '区块日志备份目录已存在',
    },
    '3200000': {
        'en_US': 'http exception',
        'zh_CN': 'http异常',
    },
    '3200001': {
        'en_US': 'invalid http client root certificate',
        'zh_CN': '无效的http客户端证书',
    },
    '3200002': {
        'en_US': 'invalid http response',
        'zh_CN': '无效的http请求',
    },
    '3200003': {
        'en_US': 'service resolved to multiple ports',
        'zh_CN': '服务对应多个端口',
    },
    '3200004': {
        'en_US': 'fail to resolve host',
        'zh_CN': '解析主机名失败',
    },
    '3200005': {
        'en_US': 'http request fail',
        'zh_CN': 'http请求失败',
    },
    '3200006': {
        'en_US': 'invalid http request',
        'zh_CN': '无效的http请求',
    },
    '3210000': {
        'en_US': 'Resource limit exception',
        'zh_CN': '资源限制异常',
    },
    '3220000': {
        'en_US': 'Mongo DB exception',
        'zh_CN': 'MongoDB异常',
    },
    '3220001': {
        'en_US': 'Fail to insert new data to Mongo DB',
        'zh_CN': 'MongoDB插入数据失败',
    },
    '3220002': {
        'en_US': 'Fail to update existing data in Mongo DB',
        'zh_CN': 'MongoDB更新数据失败',
    },
    '3230000': {
        'en_US': 'Contract API exception',
        'zh_CN': '合约API异常',
    },
    '3230001': {
        'en_US': 'Crypto API Exception',
        'zh_CN': '密码学API异常',
    },
    '3230002': {
        'en_US': 'Database API Exception',
        'zh_CN': '数据库API异常',
    },
    '3230003': {
        'en_US': 'Arithmetic Exception',
        'zh_CN': '算术异常',
    },
    '3240000': {
        'en_US': 'Snapshot exception',
        'zh_CN': '快照异常',
    },
    '3240001': {
        'en_US': 'Snapshot Validation Exception',
        'zh_CN': '快照验证异常',
    },
};

export const EOSErrorCode = {
    getEOSErrorMessage:(e:any)=> {
        const error = typeof e === 'string' ? JSON.parse(e) : e;
        console.log('sssss',error)
        if (error?.error) {
            console.log('sssss')
            const { code, details = [] } = error.error;
            console.log('sssss',code, details)
            if(details[0]?.message) {
                const errorDetail = details[0]?.message?.match(/\$\$\$(\d+)\$\$\$/);
                if(errorDetail) {
                    if(eosErrorArray[errorDetail[1]]){
                        const eosMsag1 = eosErrorArray[errorDetail[1]]['zh_CN'] ;
                        console.log('dddd',eosMsag1,errorDetail[1])
                        return eosMsag1;
                    }else{
                        return `${code}: ${details[0]?.message}`;
                    }

                }else{
                    return `${code}: ${details[0]?.message}`;
                }
            }
        }
        console.log(';dddddd')
        return 'error';
    }
}

