import axiosService from '../axios';
import { getContract, getClient } from '../client';
import { transact } from '../transact';

const env = process.env.REACT_APP_NET;

// 状态
export const planStatus = {
    1: '未激活',
    2: '进行中',
    3: '已暂停',
};
// 状态
export const issuesStatus = {
    1: '未启用',
    2: '进行中',
    3: '已结束',
};

/**
 * 对字符串（二进制）前补0
 * @param {*} str
 * @param {*} n 总长度
 * @returns
 */
export const zeroPadding = (str, n) => (Array(n).join('0') + str).slice(-n);

/**
 * 十进制转二进制
 * @returns
 */
export const decimalToBinary = num => Number(num).toString(2);

/**
 * 二进制转十进制
 * @param {*} bin
 * @returns
 */
export const binaryToDecimal = bin => parseInt(bin, 2);

// API
export default class Server {
    public static base = {
        baseUrl: '',
        // contract_name: env === 'production' ? 'amax.xchain' : 'xchainc',
        contract_name: env === 'production' ? 'amax.token' : 'amax.token',
        contract_factory_name: env === 'production' ? 'tokenfactory' : 'tokenfactory',
        contract_custody: env === 'production' ? 'amax.custody' : 'amax.custody',
        tokne_contract_name: 'amax.mtoken',
        global2_contract_name: 'global2',
        global_contract_name: 'global',
        conf_contract_name: 'mdao.conf',
    };
    /**申请充值地址*/
    public static async issuetokenOpt<T>(params): Promise<T> {
        // console.log('params--', params)
        // console.log('params--', JSON.stringify(params))
        // const contract = await getContract(this.base.contract_factory_name);
        // console.log('createtoken---', contract)
        // return contract.issuetoken(params);

        const actions = [
            {
                account: this.base.contract_factory_name, // 合约名称
                name: 'issuetoken', // 合约方法名
                data: params// 合约参数
            }
        ]
        console.log(actions,'actions--');
        return await transact(actions)
    }
    /**申请充值地址*/
    public static async createtoken<T>(_actions): Promise<T> {
        // const client = await getClient();
        // const contract = await getContract(this.base.contract_name);
        // console.log('createtoken---', contract)
        // client.transaction(
        //     actions.map(action => action.contract),
        //     obj => {
        //         for (const action of actions) {
        //             obj[action.contract.replace('.', '_')][action.name](action.data);
        //         }
        //     },
        // );
        // return client.transaction(
        //     actions.map(action => action.contract),
        //     obj => {
        //         for (const action of actions) {
        //             obj[action.contract.replace('.', '_')][action.name](action.data);
        //         }
        //     },
        // );
        console.log(_actions,'actions');
        const actions = _actions.map((item:any)=>{
            return {...item,account:item.contract}
        })
        console.log(actions,'actions--');
        return await transact(actions)
    }
    /**通过锁仓计划 和接收人 搜索 锁仓列表*/
    public static async getLockListByPlanidAndReceiver<T>(nameMix: string, nameMax: string): Promise<T> {
        return axiosService({
            url: '/v1/chain/get_table_rows',
            method: 'post',
            data: {
                code: this.base.contract_custody,
                scope: this.base.contract_custody,
                lower_bound: nameMix,
                upper_bound: nameMax,
                index_position: 5,
                key_type: 'i128',
                table: 'issues',
                limit: 10000,
                json: true,
            },
        });
    }
    /**获取币种网络列表*/
    public static getChaincoins<T>(): Promise<T> {
        return axiosService({
            url: '/v1/chain/get_table_rows',
            method: 'post',
            data: {
                code: this.base.contract_name,
                scope: this.base.contract_name,
                table: 'chaincoins',
                limit: 10000,
                json: true,
            },
        });
    }

    public static async getGlobalTable2<T>(): Promise<T> {
        return axiosService({
            url: '/v1/chain/get_table_rows',
            method: 'post',
            data: {
                code: this.base.conf_contract_name,
                scope: this.base.conf_contract_name,
                table: this.base.global2_contract_name,
                limit: 10000,
                json: true,
            },
        });
    }

    public static async getGlobalTable<T>(): Promise<T> {
        return axiosService({
            url: '/v1/chain/get_table_rows',
            method: 'post',
            data: {
                code: this.base.conf_contract_name,
                scope: this.base.conf_contract_name,
                table: this.base.global_contract_name,
                limit: 10000,
                json: true,
            },
        });
    }

    /**获取币种手续费收取比例*/
    public static getFeeRate<T>(): Promise<T> {
        return axiosService({
            url: '/v1/chain/get_table_rows',
            method: 'post',
            data: {
                code: this.base.contract_name,
                scope: this.base.contract_name,
                table: 'fee_rate',
                limit: 10000,
                json: true,
            },
        });
    }

    /**获取base网络列表*/
    public static getChains<T>(): Promise<T> {
        return axiosService({
            url: '/v1/chain/get_table_rows',
            method: 'post',
            data: {
                code: this.base.contract_name,
                scope: this.base.contract_name,
                table: 'chains',
                limit: 10000,
                json: true,
            },
        });
    }

    /**申请充值地址*/
    public static async reqxintoaddr<T>(params): Promise<T> {
        console.log('params--', params)
        // const contract = await getContract(this.base.contract_name);
        // return contract.reqxintoaddr(params);
        
        const actions = [
            {
                account: this.base.contract_name, // 合约名称
                name: 'reqxintoaddr', // 合约方法名
                data: params// 合约参数
            }
        ]
        console.log(actions,'actions--');
        return await transact(actions)
    }

    /**查询是否已有充值地址*/
    public static getUserAddress<T>(mix: string): Promise<T> {
        return axiosService({
            url: '/v1/chain/get_table_rows',
            method: 'post',
            data: {
                code: this.base.contract_name,
                scope: this.base.contract_name,
                lower_bound: mix,
                upper_bound: mix,
                index_position: 3,
                key_type: 'i128',
                table: 'xinaddrmap',
                limit: 10000,
                json: true,
            },
        });
    }

    /**获取账户余额*/
    public static async getBalanceByUser<T>(user: string, symbol: string): Promise<T> {
        // const client = await getClient()
        // const banlance = await client.getCurrencyBalance(this.base.tokne_contract_name, user, symbol)
        // console.log('banlance:', banlance)

        return axiosService({
            url: '/v1/chain/get_currency_balance',
            method: 'post',
            data: {
                code: 'amax.token',
                account: user,
                symbol,
            },
        });
    }

     /**创建跨出订单*/
     public static async createorder<T>(params): Promise<T> {
        params.to = this.base.contract_name;
        // const client: any = await getClient();

        // console.log("创建跨出订单:",this.base.tokne_contract_name, params)
        // return client.transaction([this.base.contract_name, this.base.tokne_contract_name], ({ amax_mtoken }) => {
        //     amax_mtoken.transfer(params);
        // });
        const actions = [
            {
                account: this.base.tokne_contract_name, // 合约名称
                name: 'transfer', // 合约方法名
                data: params// 合约参数
            }
        ]
        console.log(actions,'actions--');
        return await transact(actions)
    }

    /**跨入订单列表*/
    public static getTransferInOrder<T>(data): Promise<T> {
        return axiosService({
            url: '/api/stats/xchain/xinorder/list',
            method: 'post',
            data
        });
    }

    /**跨出订单列表*/
    public static getTransferOutOrder<T>(data): Promise<T> {
        return axiosService({
            url: '/api/stats/xchain/xoutorder/list',
            method: 'post',
            data,
        });
    }

    public static async getPubkeyByAccount<T>(account_name: string): Promise<T> {
        return axiosService({
            url: '/v1/chain/get_account',
            method: 'post',
            data: {
                account_name
            },
        });
    }

}
