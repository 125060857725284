import axios from 'axios';
import { message } from 'antd';
type methods = 'delete' | "post" | "get" | "put";

interface axiosParams {
  url: string,
  params?: Record<string, any>
  data?: Record<string, any>
  method: methods
}
let axiosIns:any = axios.create({
  baseURL: process.env.REACT_APP_URL ?? 'http://172.20.142.169:18888',
  timeout: 1000000,
});
axios.defaults.headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

axiosIns.defaults.responseType = 'json';

axiosIns.defaults.validateStatus =  (status:number) => {
  return true;
};
axiosIns.interceptors.request.use( (config:any) => {
  if(config.url.indexOf('/api/stats/') !== -1){
    config.baseURL = process.env.REACT_APP_NET === 'production' ? 'https://amaxscan.io' : 'https://testnet-dev.ambt.art'
  }
  // config.headers['X-Access-Token'] = User.token();
  return config;
});

axiosIns.interceptors.response.use( (response:any) => {
  let data = response.data;
  let status = response.status;

  if (data && status === 200) {
    
    if(data.status === false) {
      message.error(data.message ?? "ERROR");
      setTimeout(() => {
        window.location.href = '/';
      },3000)
      return
    }
    return Promise.resolve(response.data);
  }else {
    return Promise.reject(response);
  }
});

let ajaxMethod = ['get', 'post', 'put', 'delete'];
let api:any = {};
let timeout = {
  timeout: 12000,
};

ajaxMethod.forEach((method)=> {
  api[method] = (uri:string,data: object,config:object) => {
    return new Promise(function (resolve, reject) {

      axiosIns[method](uri,data,config).then((response:any)=> {
        if (response?.status) {
          resolve(response);
        } else {
          resolve(response);
        }
      }).catch((response:any)=> {
        reject(response)
      })
    })
  }
});

let axiosService = (params:axiosParams): Promise<any> => {
  if(params.method === "get"){
    return new Promise((resolve, reject) => {
      api.get(params.url,{params: params.params},timeout).then( (res:any) => {
        resolve(res)
      })
    })
  }else if(params.method === 'delete'){
    return new Promise((resolve, reject) => {
      api.delete(params.url,{},timeout).then( (res:any) => {
        resolve(res)
      })
    })
  }else if(params.method === 'put'){
    return new Promise((resolve, reject) => {
      api.put(params.url,params.data,timeout).then( (res:any) => {
        resolve(res)
      })
    })
  }else{
    return new Promise((resolve, reject) => {
      api.post(params.url,params.data,timeout).then( (res:any) => {
        resolve(res)
      })
    })
  }
};

export default axiosService;

