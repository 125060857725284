import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../index';
import { setUserInfo, AccountStatus } from './actions';
import getAPLinkInfo, { network, getScatter, getClient, initLink, verifyProof, onAppRemoveSession, scope } from 'common/utils/client';
import { Client, Account, ServerEventType } from "@amax/amaxup";
import { Storage } from '@/common/utils/storage';
import Server from '@/common/utils/serviceApi';

const initialState = {
    topBar: {
        title: '',
        goback: '',
    },
    account: {
        kyc: false,
        name: '',
        publicKey: '',
        status: 0,
    },

    coinList: [],
    coinInfo: {},
    chainInfo: {},
    globalCoinLange: 'en_US', 

    themeMode: 'light',
    language: 'en_US',
    chain: {
        eth: 'https://goerli.etherscan.io/',
        btc: 'https://btc.tokenview.com/',
        trx: 'https://nile.tronscan.org/',
    },
    baseChainOption: {
        // 测试网配置
        MBTC: {
            minrecharge: 0.0001,
            minWithdraw: 0.005,
        },
        METH: {
            minrecharge: 0.005,
            minWithdraw: 0.01,
        },
        MUSDT: {
            minrecharge: 1,
            minWithdraw: 50,
        },
        MBNB: {
            minrecharge: 0.001,
            minWithdraw: 0.05,
        }
    },
    transferTime: {
        btc: {
            penddingBlock: 3,
            penddingTime: 1800 // s
        },
        eth: {
            penddingBlock: 12,
            penddingTime: 300 
        },
        bsc: {
            penddingBlock: 12,
            penddingTime: 120 
        },
        tron: {
            penddingBlock: 12,
            penddingTime: 120 
        },
    },
    baseFee: {
        MBTC_btc:{
            isFixedFee: false,  //是否固定收费
            scale: 0.3, // 不是固定收费  按比例%
        },
        METH_eth:{
            isFixedFee: false,
            scale: 0.3,
        },

        MUSDT_eth:{
            isFixedFee: true, 
            scale: 0.3,
        },
        MUSDT_bsc:{
            isFixedFee: true,
            scale: 0.3,
        },
        MBNB_bsc:{
            isFixedFee: true,
            scale: 0.3,
        },
        MUSDT_tron:{
            isFixedFee: true, 
            scale: 0.3,
        },
    },
    browserScanDev: {
        eth: 'https://goerli.etherscan.io/tx/',
        btc: 'https://btc.tokenview.com/btc/tx/',
        tron: 'https://nile.tronscan.org/#/transaction/',
        bsc: 'https://testnet.bscscan.com/tx/',
        amax: 'https://testnet.amaxscan.io/transaction/'
    },
    browserScanMain: {
        eth: 'https://etherscan.io/tx/',
        btc: 'https://www.blockchain.com/btc/tx/',
        tron: 'https://tronscan.org/#/transaction/',
        bsc: 'https://bscscan.com/tx/',
        amax: 'https://amaxscan.io/transaction/'
    },
};

export const blockchains = [
    {
        chainId: network.chainId,
        name: network.blockchain,
        rpcEndpoints: [
            {
                protocol: network.protocol,
                host: network.host,
                port: 0,
            },
        ],
    },
];


export async function login_anchor() {
    const link = initLink();
    const identity = await link.login(scope);
    const {proof} = await verifyProof(
        link,
        identity,
    );
    await onAppRemoveSession();
    const walletAddress = proof.signer.actor.toString();
    const authority = proof.signer.permission.toString();
    const chainId = network.chainId;
    const publicKey = identity.session.publicKey.toString()
    
    console.log(publicKey,'publicKey');
    console.log(authority,'authority');
    Storage.setItem('authority', authority);
    Storage.setItem('walletAddress', walletAddress);
    Storage.setItem('chainId', chainId);
    sessionStorage['link']= 'yes'

    return {
        account:{name:walletAddress,authority},
        identity:{
            publicKey,
            kyc:false
        }
    }
}

const BaseInfoSlice = createSlice({
    name: 'base',
    initialState,
    reducers: {
        setTopBar(state, { payload: TopBar }) {
            state.topBar = TopBar;
        },
        setTheme(state, { payload: theme }) {
            state.themeMode = theme;
        },
        setLanguage(state, { payload: language }) {
            state.language = language;
        },
        setUserInfo(state, { payload: account }) {
            window.localStorage.setItem('user', JSON.stringify(account));
            state.account = account;
        },
        setCoinList(state, { payload: coinList }) {
            state.coinList = coinList;
        },
        setChainInfo(state, { payload: chainInfo }) {
            state.chainInfo = chainInfo;
        },
        setCoinInfo(state, { payload: coinInfo }) {
            state.coinInfo = coinInfo;
        },
        setGlobalCoinLange(state, { payload: language }) {
            state.globalCoinLange = language;
        },
    },
});

export const getUserInfo = (): AppThunk => async dispatch => {
    try {
        // let accountStatus: AccountStatus;
       
        // const scatter: any = getScatter(),
        //     _getClient: any = await getClient();
        
        //判断amaxup环境
        const client = new Client();
        client.on(ServerEventType.ACCOUNTS_CHANGED, (accounts: Account[]) => {
            // ...
            console.log(accounts,'accounts');
            if (accounts.length) {
                  const {name,publicKey} = accounts[0]
                  localStorage.setItem('walletAddress', name);
                  localStorage.setItem('authority','active');
                  dispatch(
                      setUserInfo({
                          kyc: false,
                          name: name,
                          publicKey: publicKey,
                          status: 1,
                      })
                  );
              }
          })
        if (client.isInIframe()) {
            const account = await client.getAccount()
            console.log(account,'account');
            localStorage.setItem('walletAddress', account.name);
            localStorage.setItem('authority', account.permission);
            dispatch(
                setUserInfo({
                    kyc: false,
                    name: account.name,
                    publicKey: account.publicKey,
                    status: 1,
                })
            );
            return false
        }else{
            if ((window as any).armadillo) {
                const accounts = await (window as any).armadillo.requestAccounts();
                if (accounts.length) {
                    const {account,publicKey} = accounts[0]
                    localStorage.setItem('walletAddress', account);
                    localStorage.setItem('authority','active');
                    dispatch(
                        setUserInfo({
                            kyc: false,
                            name: account,
                            publicKey: publicKey,
                            status: 1,
                        })
                    );
                }
            }else{
                if (getAPLinkInfo()) {
                    console.log('scatter--');
                    const scatter: any = getScatter();
                    const identity = await scatter.getIdentity({
                        accounts: [{ chainId: network.chainId, blockchain: network.blockchain }],
                    });
                    const account = identity?.accounts[0];
                    console.log(identity,'identity');
                    let accountStatus: AccountStatus;
                        let publicKey = ''
                        try {
                            const accountData:any = await Server.getPubkeyByAccount(account.name);
                            console.log(accountData,'accountData');
                            publicKey = accountData.permissions[0].required_auth.keys[0].key
                            accountStatus = 1;
                        } catch (e) {
                            console.log(e,'e');
                            publicKey = ''
                            accountStatus = 0;
                        }
                        Storage.setItem('walletAddress',  account.name);
                        Storage.setItem('authority', account.authority);
                        console.log(publicKey,'publicKey');
                        
                        dispatch(
                            setUserInfo({
                                kyc: identity.kyc,
                                name: account.name,
                                publicKey: publicKey,
                                status: accountStatus,
                            })
                        );
                    return
                }
               const _data = await login_anchor()
                const {account, identity} = _data
                let accountStatus: AccountStatus;
                let publicKey = ''
                try {
                    const accountData:any = await Server.getPubkeyByAccount(account.name);
                    console.log(accountData,'accountData');
                    publicKey = accountData.permissions[0].required_auth.keys[0].key
                    accountStatus = 1;
                } catch (e) {
                    console.log(e,'e');
                    publicKey = ''
                    accountStatus = 0;
                }
                Storage.setItem('walletAddress',  account.name);
                Storage.setItem('authority', account.authority);
                console.log(publicKey,'publicKey');
                
                dispatch(
                    setUserInfo({
                        kyc: identity.kyc,
                        name: account.name,
                        publicKey: publicKey,
                        status: accountStatus,
                    })
                );
                console.log('----login', identity);
            }
            
        }
        // const identity = await scatter.getIdentity({
        //     accounts: [{ chainId: network.chainId, blockchain: network.blockchain }],
        // });
        // const account = identity?.accounts[0];
        // console.log('====', identity)
    } catch (e) {
        console.error('login Error:', e);
    }
};

export const getCanchUserInfo = (): AppThunk => async dispatch => {
    try {
        const canche_account = window.localStorage.getItem('user') && JSON.parse(window.localStorage.getItem('user'));
        if(!canche_account) return;
        // const scatter: any = getScatter();
        // const identity = await scatter.getIdentity({
        //     accounts: [{ chainId: network.chainId, blockchain: network.blockchain }],
        // });
        // const account = identity?.accounts[0];
        //判断amaxup环境
        const client = new Client();
        if (client.isInIframe()) {
            const account = await client.getAccount()
            console.log(account,'account');
            localStorage.setItem('walletAddress', account.name);
            localStorage.setItem('authority', account.permission);
            if(canche_account?.name === account.name){
                dispatch(
                    setUserInfo(canche_account)
                );
            }
            return false
        }else{
            if ((window as any).armadillo) {
                const accounts = await (window as any).armadillo.requestAccounts();
                if (accounts.length) {
                    const {account,publicKey} = accounts[0]
                    if(canche_account?.name === account){
                        dispatch(
                            setUserInfo(canche_account)
                        );
                    }
                }
            }else{
                if (getAPLinkInfo()) {
                    console.log('scatter--');
                    const scatter: any = getScatter();
                    const identity = await scatter.getIdentity({
                        accounts: [{ chainId: network.chainId, blockchain: network.blockchain }],
                    });
                    const account = identity?.accounts[0];
                    if(canche_account?.name === account.name){
                        dispatch(
                            setUserInfo(canche_account)
                        );
                    }
                    return
                }
                console.log('getCanchUserInfo');
                const _data = await login_anchor()
                const {account, identity} = _data
                if(canche_account?.name === account.name){
                    dispatch(
                        setUserInfo(canche_account)
                    );
                }
            }
            
        }
        
    } catch (e) {
        console.error('login Error:', e);
    }
};

export default BaseInfoSlice.reducer;
