import getAPLinkInfo, { getClient, scope } from './client';
import {Storage} from './storage';
// import { getClient } from './client';
import { Client,Actor,Action } from "@amax/amaxup";
// amaxup
const client = new Client();

/**
 * amax
 * 调用合约
 */
export async function transact(actions: Action[]) {
  

  if (!Array.isArray(actions) || !actions.length) {
    return;
  }

  // 过滤出正确的action
  actions = addAuthorization(actions.filter((item:any) => checkAction(item)));
  
  try {
    if (client.isInIframe()) {
      // amaxup提交交易
      return await client.transact(actions);
    }else{
      // armadillo提交交易
      if ((window as any).armadillo) {
        return await (window as any).armadillo.transact({
          transaction: { // 同api的transact一样
            actions
          },
          // 此参数APLink暂时不支持
          // config: { // 可以不传，具体类型 import { TransactConfig } from '@amax/amaxjs-v2/dist/eosjs-api-interfaces';
          //   broadcast: false
          // }
        });
      }else{
        if (getAPLinkInfo()) {
          return scatter(actions)
        }
        // anchor提交交易
        return await anchor(actions)
      }
      
    }
  } catch (e) {
    console.log(e,'from transact');
    const error: any = typeof e === 'string' ? JSON.parse(e) : e;
    throw e;
  }
}

function checkAction(action: Action) {
  const { account, data, name } = action;
  if (!account || !data || !name) {
    return false;
  }
  return true;
}

/**
 * 给action增加authorization属性
 * @param actions
 * @returns
 */
function addAuthorization(actions: Action[]): Action[] {
  const walletAddress = Storage.getItem('walletAddress');
  const authority = Storage.getItem('authority');
  for (const action of actions) {
    if (!action.authorization || action.authorization.length === 0) {
      action.authorization = [
        {
          actor: walletAddress,
          permission: authority,
        },
      ];
    }
  }
  return actions;
}


/**
 * Scatter发起交易
 * @param actions
 * @returns
 */
async function scatter(actions: Action[]) {
  const client = await getClient();
  return await client.transaction({ actions },{broadcast:false});
}


/**
 * Anchor发起交易
 * @param actions
 * @returns
 */

export async function anchor(actions: Action[]) {
  const session = await (window as any).__LINK__.restoreSession(scope);
  return await session.transact({ actions }, { broadcast:false });
}