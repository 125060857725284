import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import store, { AppState } from './state';

import MainRoute from './routeLayout/MainRoute'
import { drak, light } from './theme';

import './locale/main';

const Providers: React.FC = ({ children }) => {
    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
};

const StyledThemeProvider = props => {
    const baseInfo = useSelector((state: AppState) => state.baseInfo);
    return <ThemeProvider theme={baseInfo.themeMode === 'light' ? light : drak} {...props} />;
};

const Entry: React.ComponentType = () => {
    return (
        <Providers>
            <BrowserRouter>
                <StyledThemeProvider>
                    <Switch>
                        <Route path="/" component={MainRoute} />
                        <Route path={'*'} component={MainRoute} />
                    </Switch>
                </StyledThemeProvider>
            </BrowserRouter>
        </Providers>
    );
};
export default Entry;
