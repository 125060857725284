import { FC, ReactElement, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

const Home = lazy(() => import('@/page/Home'));
const CreateToken = lazy(() => import('@/page/CreateToken'));
const TopBar = lazy(() => import('@/components/TopBar'));

const MainRoute: FC = (): ReactElement => {
    return (
        <Suspense fallback={<div></div>}>
            <Switch>
                <Route path="/home" component={Home} />
                <TopBar>
                    <Route path="/" component={CreateToken} />
                    <Route path="/create-token" component={CreateToken} />
                </TopBar>
                <Route exact path={'*'} render={() => <Redirect to="/create-token" />} />
            </Switch>
        </Suspense>
    );
};
export default MainRoute;
