import i18n from 'i18next';

import {initReactI18next} from 'react-i18next';

let defaultLange = 'en_US'
const lange = window.localStorage.getItem('lange');

if (lange) {
    if (['en_US', 'zh_CN'].includes(lange)) {
        defaultLange = lange
    } else {
        let navigatorLange = navigator.language;
        if (navigatorLange === 'zh-CN') {
            navigatorLange = 'zh_CN'
        }

        if (['en_US', 'zh_CN'].includes(navigatorLange)) {
            defaultLange = navigatorLange
        }
    }

} else {
    let navigatorLange = navigator.language;
    if (navigatorLange === 'zh-CN') {
        navigatorLange = 'zh_CN'
    }
    if (['en_US', 'zh_CN'].includes(navigatorLange)) {
        defaultLange = navigatorLange
    }
}

i18n
    // .use(LanguageDetector)  //嗅探当前浏览器语言
    .use(initReactI18next) //init i18next
    .init({
        //引入资源文件
        resources: {
            en_US: {
                translation: require('./en.json'),
            },
            zh_CN: {
                translation: require('./zh_CN.json'),
            }
        },

        //选择默认语言，选择内容为上述配置中的key，即en/zh
        fallbackLng: defaultLange,
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
