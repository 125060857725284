import React from 'react';
import ReactDOM from 'react-dom';
import VConsole from 'vconsole';
import 'style/index.scss';
import 'utils/global';
import 'utils/met';

import Entry from './entry';
import reportWebVitals from './reportWebVitals';
import {Scrollbars} from "react-custom-scrollbars";
console.log('---env', process.env)
if(process.env.REACT_APP_NET === 'uat'){
  new VConsole();
}
ReactDOM.render(
  <Scrollbars>
    <Entry />
  </Scrollbars>,
  document.getElementById('root')
);

reportWebVitals();
